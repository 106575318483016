import Swiper, { Pagination, Autoplay } from 'swiper';
Swiper.use([Pagination, Autoplay]);

const heroTexts = document.querySelectorAll(".hero-text");

if (heroTexts?.length > 0) {
    heroTexts.forEach(heroText => {
        const heroSlider = heroText.querySelector(".hero-text__slider");
        if (heroSlider) {
            const slider = new Swiper(heroSlider, {
                slidesPerView: 1,
                disableOnInteraction: false,
                loop: true,
                autoplay: {
                    delay: 5000,
                },
                pagination: {
                    el: heroText.querySelector(".hero-text__pagination"),
                    type: 'bullets',
                    bulletClass: 'hero-text__slider-bullet',
                    bulletActiveClass: 'active',
                    clickable: true,
                },
            })
        }
    });
}